$primary: #f94615;

@import "./scss/normalize";
@import "./scss/type";
@import "./scss/form";
@import "./scss/grid";
@import "./scss/social-icons";

// Header
@import "./scss/header";

// Hero
@import "./scss/hero";
@import "./scss/hero-nav";
@import "./scss/hero-content";

// About
@import "./scss/about";

// Contact
@import "./scss/contact";

// Footer
@import "./scss/footer";
