.form-group {
  margin-bottom: 20px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 15px 15px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #2a2a2a;
  appearance: none;

  &:focus {
    border-color: $primary;
    outline: 0;
  }

  &.invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 2px #dc3545;
  }
}

textarea.form-control {
  min-height: 200px;
  resize: vertical;
}
