.hero-content {
  padding: 20px 0;
  color: #403a3a;

  &__tab {
    display: none;

    &.active {
      display: block;
    }
  }

  .col {
    flex: 1 1 100%;
    max-width: 100%;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: 15px;
    color: $primary;
    font-size: 32px;
  }

  p {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 5px;
  }

  ul {
    margin: 0 0 15px;
    padding-left: 1.5em;
  }

  small {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    padding: 40px 0;

    .row {
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
    }

    .col {
      flex: 0 1 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 992px) {
    padding: 80px 0;
  }
}
