.hero-nav {
  background-color: #ebeaea;
  border-top: 4px solid $primary;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    display: block;
  }

  &__link {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    @extend .din;
    font-size: 22px;
    line-height: 1.1;
    color: #2a2a2a;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #dfdddd;
    }

    &:focus {
      outline: 0;
    }

    &.active {
      color: #fff;
      background-color: $primary;
    }
  }

  @media (min-width: 576px) {
    &__list {
      margin: 0 auto;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
    }

    &__list-item {
      flex: 1 1 0;
      display: flex;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 5px;
      font-size: 16px;

      span {
        display: inline-block;
        max-width: 130px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 17px 0 17px;
        border-color: $primary transparent transparent transparent;
        opacity: 0;
      }

      &.active::before {
        opacity: 1;
      }

      &.active {
        color: inherit;
        background-color: inherit;
      }
    }
  }

  @media (min-width: 768px) {
    &__list {
      flex-wrap: nowrap;
    }

    &__link {
      font-size: 18px;
      padding: 15px 10px;
    }
  }

  @media (min-width: 992px) {
    &__link {
      font-size: 22px;
      padding: 20px 15px;
    }
  }
}
