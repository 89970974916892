.social-icons {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  color: $primary;

  li + li {
    margin-left: 12px;
  }

  a {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border: 2px solid currentColor;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    outline: 0;

    &:hover {
      border-color: $primary;
      background-color: $primary;
      color: #fff;
    }

    &:focus {
      box-shadow: 0 0 0 4px rgba($primary, 50%);
    }
  }
}
