.container {
  width: 100%;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.col {
  padding-left: 15px;
  padding-right: 15px;
}
