.hero {
  &__tab {
    position: relative;
    display: none;
    align-items: center;
    background: #ebeaea center center no-repeat;
    background-size: cover;
    min-height: 400px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      background-color: rgba($primary, 0.5);
    }

    &::before {
      z-index: 2;
      width: 60%;
      clip-path: polygon(0 0, 100% 0, 40% 100%, 0% 100%);
    }

    &::after {
      z-index: 1;
      width: 80%;
      clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
    }

    &.active {
      display: flex;
    }

    &.wireless {
      background-image: url("./assets/wire-free-security-1920.jpg");
    }

    &.wired {
      background-image: url("./assets/wired-alarm-1920.jpg");
    }

    &.cctv {
      background-image: url("./assets/cctv-1920.jpg");
    }

    &.doorbell {
      background-image: url("./assets/doorbell-1920.jpg");
    }

    &.access-control {
      background-image: url("./assets/access-control-1920.jpg");
    }

    &.maintenance {
      background-image: url("./assets/maintenance-1920.jpg");
    }

    .container {
      position: relative;
      z-index: 5;
      padding-top: 80px;
    }

    h1 {
      font-size: 56px;
      color: #fff;
      line-height: 0.85;
    }
  }

  @media (min-width: 576px) {
    &__tab {
      min-height: 520px;
      min-height: 69vw;

      h1 {
        font-size: 70px;
        font-size: 10vw;
      }
    }
  }

  @media (min-width: 768px) {
    &__tab {
      min-height: 600px;
      min-height: 60vw;

      &::before {
        width: 40%;
      }

      &::after {
        width: 60%;
      }
    }
  }

  @media (min-width: 1200px) {
    &__tab {
      min-height: 720px;

      h1 {
        font-size: 120px;
      }
    }
  }
}
