.contact {
  padding: 50px 0;

  .col {
    flex: 1 1 100%;
    max-width: 100%;
  }

  h2 {
    margin-bottom: 15px;
    color: $primary;
    font-size: 32px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 40px 0;

    li {
      display: block;
    }

    li + li {
      margin-top: 15px;
    }

    a {
      display: inline-flex;
      align-items: center;
      color: #2a2a2a;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;

      svg {
        color: $primary;
        margin-right: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .social-icons {
    margin-bottom: 40px;
  }

  &__disclaimer {
    margin-bottom: 15px;
    max-width: 450px;
    color: #9c9c9c;
    font-size: 14px;
  }

  &__lead {
    margin: 0 0 15px;
  }

  &__form p {
    font-size: 18px;
    font-weight: bold;

    &.success {
      color: #198754;
    }

    &.error {
      color: #dc3545;
    }
  }

  @media (min-width: 768px) {
    .col {
      flex: 0 1 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 992px) {
    padding: 100px 0;

    &__list {
      margin-bottom: 80px;
    }
  }
}
