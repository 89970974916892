.about {
  padding: 90px 0;
  background-color: #413b3b;
  color: #fff;
  clip-path: polygon(0 0, 100% 40px, 100% calc(100% - 40px), 0% 100%);

  .col {
    flex: 1 1 100%;
    max-width: 100%;
  }

  &__title {
    margin-bottom: 15px;
    font-size: 50px;
    font-size: 14.5vw;
    line-height: 0.85;
  }

  &__lead {
    font-size: 18px;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    .row {
      flex-wrap: nowrap;
      align-items: center;
    }

    &__header.col {
      flex: 0 1 40%;
      max-width: 40%;
    }

    &__content.col {
      flex: 0 1 60%;
      max-width: 60%;
    }

    &__title {
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    padding: 180px 0;
    clip-path: polygon(0 0, 100% 80px, 100% calc(100% - 80px), 0% 100%);

    &__lead {
      font-size: 20px;
    }
  }

  @media (min-width: 1200px) {
    &__title {
      font-size: 170px;
    }
  }
}
