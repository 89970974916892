html {
  scroll-behavior: smooth;
}

body {
  font-family: lato, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.din {
  font-family: din-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.button {
  @extend .din;
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 22px;
  color: #fff;
  background-color: $primary;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.02em;
  transition: all 0.2s ease-in-out;
  appearance: none;
  border: 0;
  cursor: pointer;

  &:hover {
    background-color: darken($primary, 10%);
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px rgba($primary, 50%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .din;
  margin: 0;
  line-height: 1.25;
  text-transform: uppercase;
}

img {
  max-width: 100%;
}

p {
  margin: 0 0 15px;
}
