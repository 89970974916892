.site-header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  .container {
    max-width: 600px;
  }

  .row {
    flex-wrap: wrap;
    align-items: center;
  }

  &__logo {
    flex: 0 1 50%;
    max-width: 50%;

    img {
      width: 100%;
      max-width: 225px;
    }
  }

  &__menu {
    flex: 1 1 100%;

    ul {
      list-style: none;
      padding: 0;
      margin: 10px -10px 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
    }

    a {
      display: inline-block;
      padding: 10px;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      line-height: 1.25;
      outline: 0;
      transition: all 0.2s ease-in-out;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        box-shadow: 0 0 0 4px rgba(#fff, 50%);
      }
    }
  }

  &__action {
    flex: 0 1 50%;
    max-width: 50%;

    .button {
      max-width: 200px;
      margin-left: auto;
    }
  }

  @media (min-width: 992px) {
    padding: 20px 0;

    .container {
      max-width: 1230px;
    }

    .row {
      flex-wrap: nowrap;
    }

    &__logo {
      flex: 1 1 auto;
      max-width: none;
    }

    &__menu {
      flex: 0 1 auto;
      order: 1;

      ul {
        margin-top: 0;
      }

      li {
        padding: 0 15px;
      }
    }

    &__action {
      flex: 0 1 auto;
      order: 2;
      width: 230px;
    }
  }
}
