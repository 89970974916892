.site-footer {
  padding: 10px 0;
  color: #fff;
  background-color: $primary;

  .row {
    flex-wrap: nowrap;
    align-items: center;
  }

  .social-icons {
    color: #fff;

    a {
      &:hover {
        border-color: #fff;
        background-color: #fff;
        color: $primary;
      }

      &:focus {
        box-shadow: 0 0 0 4px rgba(#fff, 50%);
      }
    }
  }

  &__copyright {
    flex: 1 1 auto;
  }

  .copyright {
    font-size: 12px;
  }

  @media (min-width: 576px) {
    padding: 20px 0;

    &__copyright {
      text-align: center;

      .copyright {
        display: inline-block;
        margin-left: 130px;
      }
    }

    &__social {
      flex: 0 0 auto;
    }
  }
}
